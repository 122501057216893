<template>
  <div class="">

    <header-tab :tabs="['Home', 'Submitted application']" @tab="toggleTabs" :defaulttab="2"></header-tab>

    <div class="page-title px-5" v-if="openTab === 1">
      <vacancies-all></vacancies-all>
    </div>
    <div class="d-flex flex-column" v-if="openTab === 2">
      <job-replying v-if="loader.loaderdata === false"></job-replying>
      <template v-else>
        <v-skeleton-loader class="mx-auto pb-2" max-width="100%" type="list-item-two-line" v-for="(i, j) in 5" :key="j">
        </v-skeleton-loader>
      </template>
      <v-pagination class="text-center" circle v-model="page" :length="pages" :total-visible="visiblePages"
        @input="updatevacanciesPage"></v-pagination>
    </div>
  </div>
</template>
<script>

import {
  mdiMagnify, mdiMapMarkerOutline, mdiClose, mdiShapeRectanglePlus, mdiHelpCircleOutline,
  mdiCalendar, mdiKeyboardBackspace, mdiAlertOutline, mdiBriefcase, mdiOpenInNew, mdiShareAll, mdiEye
} from '@mdi/js'
import { Drequest } from '@/plugins/Drequest'
import { Utils } from '@/plugins/Utils';
import HeaderSearchPage from '@/views/components/HeaderSearchPage.vue'
import HeaderPage from '@/views/pages/components/HeaderPage.vue'
import VacanciesItem from '@/views/dashboard/professional/vacancies/VacanciesItem.vue';
import EmptyData from '@/views/components/utils/EmptyData.vue';
import JobReplying from '@/views/dashboard/professional/vacancies/JobReplying.vue';
import HeaderTab from '@/views/dashboard/components/HeaderTab.vue';
import VacanciesAll from '@/views/dashboard/professional/vacancies/VacanciesAll.vue';

export default {
  components: {
    HeaderSearchPage,
    HeaderPage,
    VacanciesItem,
    JobReplying,
    EmptyData,
    HeaderTab,
    VacanciesAll
  },
  data() {
    return {
      no_resultitem: 0,
      searchDetails: '',
      nodats: 'vacancies',

      openTab: 2,

      tabs: [
        { title: 'Saved', icon: mdiShareAll },
        { title: 'recommandation', icon: mdiShareAll },
        // { title: 'Invite someone', icon: mdiEye }
      ],
      loader: {
        loadSearchResult: false,
        loaderdata: false
      },
      externalProposals: {
        loader: true,
        size: 5,
        datas: []
      },
      submit: true,
      jobitem: {},
      vacancieslist: [],
      tmpsVacancieslist: [],
      headersSearch: {
        title: "Tops relevants jobs",
        subtitle: "Choose from over the most relevants jobs we have selected for you.",
        icon: '',
        search: {
          offer: {
            text: 'Search by offer',
            icon: mdiMagnify
          },
          location: {
            text: 'Search by location',
            icon: mdiMapMarkerOutline
          },
          domain: {
            text: 'Search by domain',
            icon: mdiMapMarkerOutline
          },
          company: {
            text: 'Search by company',
            icon: mdiMapMarkerOutline
          },
        }
      },
      headers: {
        titlepage: 'More vacancies',
        icon: this.icons.mdiMagnify
      },

      page: 1,
      pageSize: 5,
      visiblePages: 8,
      historyVacancies: [],
      listCount: 0,
    }
  },
  computed: {
    vacanciesItems() {
      return this.historyVacancies
    },
    pages() {
      if (this.pageSize == null || this.listCount == null) return 0;
      return Math.ceil(this.listCount / this.pageSize);
    }
  },
  methods: {
    toggleTabs(tabNumber) {
      this.openTab = tabNumber
    },
    selectExternalProposal(item) {
      var t_link = Utils.replaceAll(item.link, '/', '')
      t_link = Utils.replaceAll(item.link, 'fr', '')

      this.$router.push({ name: 'vacancies.external.details', params: { offer_title: t_link } })
    },
    closeSearch() {
      this.loader.loaderdata = true
      this.vacancieslist = this.tmpsVacancieslist
      this.initPagination()
      this.no_resultitem = 0
      this.loader.loaderdata = false
      // this.loadInitialJob()
    },

    searchActions(data) {
      // var search_str = `job.search?offer`
      this.loader.loadSearchResult = true
      this.loader.loaderdata = true
      const s_offer = data.title.isEmpty === false ? data.title.value : ''
      const s_domain = data.domain.isEmpty === false ? data.domain.value : ''
      const s_location = data.location.isEmpty === false ? data.location.value : ''
      const s_company = data.company.isEmpty === false ? data.company.value : ''

      this.tmpsVacancieslist = this.vacancieslist
      this.vacancieslist = []
      Drequest.api(`job.search?offer=${s_offer}&domain=${s_domain}&location=${s_location}&company=${s_company}`)
        .get((response) => {
          if (response.success === true) {
            response.listEntity.forEach((value, index) => {
              this.vacancieslist.push(value)
            });
            this.jobitem = response.listEntity[0]
            this.searchDetails = response.detail
            this.no_resultitem = response.nb_element
            this.loader.loadSearchResult = false
            this.loader.loaderdata = false
          }
          else {
            this.loader.loadSearchResult = false
            this.searchDetails = response.detail
            this.loader.loaderdata = false
            this.no_resultitem = 0
          }
        })
        .catch((err) => {
          this.loader.loadSearchResult = false
          this.loader.loaderdata = false

          this.no_resultitem = 0
        });
      // this.vacancieslist = this.methodsSearch(mainInputText)
      this.initPagination()
    },
    methodsSearch(in_name, in_location) {
      return this.vacancieslist.filter((item) => {
        return in_name.toLowerCase().split(' ').every(v => item.title.toLowerCase().includes(v))
      })
    },
    async selectedjob(value) {
      this.$router.push({ name: 'vacancies.details', params: { offer_id: value.id, offer_title: Utils.replaceAll(value.title.trim(), ' ', '-') } })
      this.jobitem = value
    },
    async loadInitialJob() {
      this.loader.loaderdata = true
      Drequest.api(`job.combinealls`)
        .get((response) => {
          if (response.success === true) {
            response.listEntity.forEach((value, index) => {
              this.vacancieslist.push(value)
            });
            this.loader.loaderdata = false
            this.no_resultitem = this.vacancieslist.length
            this.initPagination()
          }
          else {
            this.loader.loaderdata = false
            this.no_resultitem = 0
          }
        })
        .catch((err) => {
          this.loader.loaderdata = false
          this.no_resultitem = 0
        });
    },

    initPagination() {
      this.listCount = this.vacancieslist.length;
      if (this.listCount < this.pageSize) {
        this.historyVacancies = this.vacancieslist;
      } else {
        this.historyVacancies = this.vacancieslist.slice(0, this.pageSize);
      }
    },
    updatevacanciesPage(pageIndex) {
      let _start = (pageIndex - 1) * this.pageSize;
      let _end = pageIndex * this.pageSize;
      this.historyVacancies = this.vacancieslist.slice(_start, _end);
      this.page = pageIndex;
    }
  },
  beforeMount() {
    // this.loadInitialJob()
    this.updatevacanciesPage(this.page);
  },
  setup(props) {
    const icons = { mdiClose, mdiShapeRectanglePlus, mdiMapMarkerOutline, mdiHelpCircleOutline, mdiCalendar, mdiKeyboardBackspace, mdiAlertOutline, mdiBriefcase, mdiOpenInNew }

    return {
      icons
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/tabs.scss';
</style>